import styled from '@emotion/styled'
import { Swiper } from 'swiper/react'

import { mediaMax } from '@/styles/mixins'

import 'swiper/css'
import 'swiper/css/virtual'

import {
  LeftArrow,
  RightArrow,
  Wrapper as ArrowWrapper
} from '@/components/Block/Sliders/atoms/Arrows/style'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SwiperContent = styled(Swiper)`
  width: 100%;
  overflow: visible !important;

  .swiper-slide {
    width: 260px;
    margin-right: 2rem;
  }
`

export const ActionWrapper = styled.div`
  margin-top: 4rem;
  width: 100%;

  ${ArrowWrapper} {
    justify-content: flex-start !important;
    flex-direction: row !important;

    ${LeftArrow},
    ${RightArrow} {
      transform: rotate(0deg) !important;
    }
    ${mediaMax.xs}{
      display: none;
    }
  }
`
