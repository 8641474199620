import * as React from 'react'

import { useRecoilValue } from 'recoil'
import { isMobileState } from '@/recoil/layout'
import { GridContainer } from '@/components/Grid'
import CircularLabelButton from '@/components/CircularLabelButton'
import LocalizedGatsbyLink from '@/components/LocalizedGatsbyLink'

import resolveAssetURL from '@/utils/resolveAssetURL'

import {
  Container,
  Wrapper,
  Content,
  SubTitle,
  Title,
  Text,
  TextContent,
  WrapperCard,
  ImageBg,
  Overlay
} from './style'

import { useTheme } from '@emotion/react'

const NewsFeed = ({
  title = '',
  subtitle = '',
  text = '',
  item
}) => {
  const isMobile = useRecoilValue(isMobileState)

  const handleClick = () => {
    if (action) {
      action()
    } else {
      window.scrollTo({
        top: window.innerHeight,
        behavior: 'smooth'
      })
    }
  }

  return (
    <Container>
      <GridContainer>
        <Wrapper>
          <TextContent>
            { subtitle.length && <SubTitle>{ subtitle }</SubTitle> }
            { title.length && <Title>{ title }</Title> }
            { text.length && <Text>{ text }</Text> }
          </TextContent>

          <WrapperCard>
            <LocalizedGatsbyLink to={item.url} title={item.title}>
              {isMobile &&
              (<CircularLabelButton
                  id="textcercle"
                  label='Read this article'
                  nbLabels={ 3 }
                  color='white'
                  icon='arrow-right'
                  to={ item.url }
                />)}
              <Overlay />
              <ImageBg loading='lazy' src={ resolveAssetURL(item.image) } alt={ item.alt } />
              {!isMobile && <Content>
                <TextContent>
                  { item.subtitle && <SubTitle>{ item.subtitle }</SubTitle> }
                  { item.title && <Title>{ item.title }</Title> }
                  { item.text && <Text>{ item.text }</Text> }
                </TextContent>
                <CircularLabelButton
                  id="textcercle"
                  label='Read this article'
                  nbLabels={ 3 }
                  color='white'
                  icon='arrow-right'
                  to={ item.url }
                />
              </Content>}
            </LocalizedGatsbyLink>
          </WrapperCard>
          {isMobile && (
            <Content>
              <TextContent>
                { item.subtitle && <SubTitle>{ item.subtitle }</SubTitle> }
                { item.title && <Title>{ item.title }</Title> }
                { item.text && <Text>{ item.text }</Text> }
              </TextContent>

            </Content>
          )}
        </Wrapper>
      </GridContainer>
    </Container>
  )
}

export default NewsFeed
