import styled from '@emotion/styled'
import { Button as CircularButton } from '@/components/CircularLabelButton/style'
import {
  mediaMax,
  mediaMin,
  sectionTitle,
  sectionSubtitle
} from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  padding: 2rem 0 4rem;
  margin-top: 8rem;
  ${mediaMax.sm}{
    margin-top: 0;
  }
`
  
export const Wrapper = styled.div`
  width: 100%;
`

export const TextContent = styled.div`
  display: flex;
  align-items: flex-start;  
  flex-direction: column;
  justify-content: flex-start;
  width: 45%;

  ${mediaMax.xs} {
    width: 100%;
    margin-top: 3rem;
  }
`

export const SubTitle = styled.span`
  ${sectionSubtitle};
  color: ${({ theme }) => theme.colors.green};
  white-space: nowrap;
  margin-bottom: 2rem;
`

export const Title = styled.h2`
  ${sectionTitle};
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.green};
  font-size: 7rem;
  font-weight: 400;
`

export const Text = styled.div`
  color: ${({ theme }) => `${theme.colors.brown}`};
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: .1rem;
  margin-bottom: 5rem;

  ${mediaMax.xs} {
    margin-bottom: 3rem;
  }
`

export const WrapperCard = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  background-position: center;
  height: 90vh;
  padding: 6rem;
  box-sizing: border-box;

  a{
    text-decoration: none;
  }
  ${CircularButton}{
    position: absolute;
    z-index: 11;
    right: 5%;
    bottom: 5%;
  }

  ${mediaMax.xs} {
    height: initial;
    padding-bottom: 80%;
    margin-bottom: 2rem;
  }

  ${SubTitle} {
    color: ${({ theme }) => `${theme.colors.white}`};
    margin-bottom: 1rem;
  }

  ${Title} {
    color: ${({ theme }) => `${theme.colors.white}`};
    font-size: 4rem;
    margin-bottom: 2rem;
  }
  
  ${Text} {
    margin-bottom: 0;
    color: ${({ theme }) => `${theme.colors.white}`};
  }

 
`

export const ImageBg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  // z-index: 9;

  ${mediaMax.xs}{
    object-position: center;
  }
`
export const Overlay = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => `rgba(0,0,0, 0.2)`};
`

export const Content = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaMax.xs} {
    ${SubTitle} {
      margin-top: 3rem;
    }
  
    ${Title} {
      color: ${({ theme }) => `${theme.colors.black}`};
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }
    
    ${Text} {
      margin-bottom: 0;
      color: ${({ theme }) => `${theme.colors.grey}`};
    }
  }
`