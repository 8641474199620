import * as React from 'react'
import PropTypes from 'prop-types'

import { GridContainer } from '@/components/Grid'
import PodcastSlider from '@/components/Block/Sliders/PodcastSlider'
import Filters from '@/components/Filters'

import {
  Container,
  Wrapper,
  Content,
  Subtitle,
  Title,
  Wysiwyg
} from './style'

const Podcast = ({ items, title = '', subtitle = '', text = '', filters = null}) => {
  return (
    <Container id="listen">
      <GridContainer>
        <Wrapper>
          <Content>
            {
              !!title.length && (
                <Title dangerouslySetInnerHTML={ { __html: title } } />
              )
            }
          </Content>

        </Wrapper>
      </GridContainer>
      <PodcastSlider items={ items } />
    </Container>
  )
}

Podcast.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    ref: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number
  }))
}

export default Podcast
