import styled from '@emotion/styled'

import { mediaMax, sectionSubtitle, sectionTitle } from '@/styles/mixins'

import WysiwygBase from '@/components/Wysiwyg'

export const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0 2rem;
`
