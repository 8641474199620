import * as React from 'react'
import PropTypes from 'prop-types'

import { SwiperSlide } from 'swiper/react'

import { GridContainer } from '@/components/Grid'
import Arrows from '@/components/Block/Sliders/atoms/Arrows'
import MediaCard from './MediaCard'

import {
  Container,
  SwiperContent,
  ActionWrapper
} from './style'

const MediaSlider = ({ items }) => {
  return (
    <Container>
      <GridContainer>
        <SwiperContent slidesPerView='auto'>
          {
            items.map((v, k) => (
              <SwiperSlide key={ `experience-${k}` }>
                <MediaCard item={ v } />
              </SwiperSlide>
            ))
          }

          <ActionWrapper>
            <Arrows display='inverted' />
          </ActionWrapper>
        </SwiperContent>
      </GridContainer>
    </Container>
  )
}

MediaSlider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    ref: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number
  }))
}

export default MediaSlider
