import styled from '@emotion/styled'

import { mediaMax, sectionSubtitle, sectionTitle } from '@/styles/mixins'

import WysiwygBase from '@/components/Wysiwyg'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 7rem 0;
  overflow: hidden;

  ${mediaMax.xs} {
    padding: 2rem 0;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4.4rem;
`

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`

export const Title = styled.span`
  ${sectionTitle()}
  margin-bottom: 2.3rem;
  color: ${({ theme }) => theme.colors.green};
  font-size: 7rem;
  font-weight: 400;

  ${mediaMax.xs} {
    font-size: 4.2rem;
    width: 50%;
    margin-bottom: 0;
  }
`