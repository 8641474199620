import styled from '@emotion/styled'
import { sectionTitle } from '@/styles/mixins'
import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  // height: 100%;
  // padding-top: 100%;
  position: relative;
`

export const IconShareWrapper = styled.div`
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 1;
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  > *:not(.icon-pause) {
    position: relative;
    left: 2px;
  }
`

export const Wrapper = styled.div`
  position: relative;
  padding: 3rem;
  width: 100%;
  // padding-top: 100%;
  // position: absolute;
  // top: 0;
  // left: 0;
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  // flex-direction: column;
  cursor: pointer;
  // transition: background-color ease-out .3s;
  overflow: hidden;

  &:after {
    content: '';
    display:block;
    padding-bottom: 100%;
  }
  ${mediaMax.xs}{
    width: 70vw;
  }
`

export const Title = styled.h2`
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
`

export const TextBg = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  pointer-events: none;

  > div {
    width: 100%;
    font-size: 10rem;
    padding: 0 2rem;
    box-sizing: border-box,
    ${sectionTitle()}
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.3;
    ${mediaMax.xs}{
      font-size: 10rem;
    }
  }

  > div:nth-child(odd){
    text-align: right;
  }

`
export const Category = styled.div`
  font-size: 1.5rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.green};
  margin: 2rem 0;
`

export const Text = styled.p`
  font-size: 1.5rem;
  width: 80%;
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.green};
  line-height: 1.5;
`

export const Audio = styled.audio`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: none;
`