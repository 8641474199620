import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { GridContainer } from '@/components/Grid'
import Filters from '@/components/Filters'
import VideoSlider from '@/components/Block/Sliders/VideoSlider'

import {
  Container,
  Wrapper,
  Content,
  Title
} from './style'

const LearnManners = ({ items, title = '', filters = null }) => {
  const [data, setData] = useState(items)

  const filterData = (activeFilter) => {
    const results = items.filter((item) => { return item.catId === activeFilter })
    setData(activeFilter === 'all' ? items : results)
  }

  return (
    <Container>
      <GridContainer>
        <Wrapper>
          <Content>
            {
              !!title.length && (
                <Title dangerouslySetInnerHTML={ { __html: title } } />
              )
            }
          </Content>
        </Wrapper>
      </GridContainer>
      <Filters
        label={ filters.placeholder }
        filtersItems={ filters.items }
        onChangeCallback={ filterData }
      />
      <VideoSlider
        medias={ data }
        popin
      />
    </Container>
  )
}

LearnManners.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    ref: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number
  }))
}

export default LearnManners
