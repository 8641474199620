import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@emotion/react'

import { useSetRecoilState } from 'recoil'
import { popupManager, popupDataManager } from '@/recoil/popup'
import { EVENT_POPUP_ID } from '@/components/Popup/config'
import Icon from '@/components/Icon'

import { formatDay } from '@/utils/date'

import {
  Container,
  Wrapper,
  IconWrapper,
  IconShareWrapper,
  Title,
  Category,
  Text,
  TextBg,
  Audio
} from './style'

const PodcastCard = ({ item }) => {
  const theme = useTheme()
  const [isPlaying, setIsPlaying] = useState(false)
  const audio = useRef()

  const isExternalAudio = !item.url.match(/.(oga|mp3)$/i)

  // const setPopup = useSetRecoilState(popupManager)
  // const setPopupData = useSetRecoilState(popupDataManager)

  // const getStatus = () => ((new Date()).getTime() > (new Date(item.date)).getTime()) ? 'closed' : 'open'

  const handleClick = () => {
    if (isExternalAudio) {
      window.open(item.url, '_blank').focus()
    } else {
      setIsPlaying(!isPlaying)
      if (isPlaying) { audio.current.pause() } else { audio.current.play() }
    }
    // setPopupData(item)
    // setPopup(EVENT_POPUP_ID)
  }

  const formattedBg = () => {
    const words = item.name.split(' ')
    let text = ''

    for (let i = 0; i < words.length; i++) {
      text += `<div>${words[i]}</div>`
    }

    return text
  }

  return (
    <Container>
      <Wrapper style={ { backgroundColor: item.color } } onClick={ handleClick }>
        {/* <IconShareWrapper>
          <Icon
            name='share'
            size={ 20 }
            color={ theme.colors.white }
          />
        </IconShareWrapper> */}
        <IconWrapper>
          {!isPlaying && <Icon
            name='play'
            size={ 30 }
            color={ theme.colors.white }
                         />}
          {isPlaying && <Icon
            name='pause'
            size={ 30 }
            color={ theme.colors.white }
                        />}
        </IconWrapper>
        <TextBg dangerouslySetInnerHTML={ { __html: formattedBg() } } />
        <Title>{item.name}</Title>
        { !isExternalAudio && <Audio controls src={ item.url } ref={ audio } />}
      </Wrapper>
      <Category>{item.category}</Category>
      <Text>{item.text}</Text>
    </Container>
  )
}

PodcastCard.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    category: PropTypes.string,
    text: PropTypes.string,
    color: PropTypes.string
  })
}

export default PodcastCard
