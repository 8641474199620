import React, { useState } from 'react'
import Filters from '@/components/Filters'
import MediaSlider from '@/components/Block/Sliders/MediaSlider'

import {
  Wrapper,
  Container
} from './style'

const RECIPE_CAT_ID = '122'

const JournalMedias = ({ items, filters = null }) => {
  const recipesIndex = filters.findIndex(f => f.catId === RECIPE_CAT_ID)
  const hasRecipes = recipesIndex >= 0

  const formattedFilters = [...filters]
  let formattedItems = items
  let recipesItems = []

  if (hasRecipes) {
    formattedFilters.splice(recipesIndex, 1)
    formattedItems = formattedItems.filter(i => i.catId !== RECIPE_CAT_ID)
    recipesItems = items.filter(i => i.catId === RECIPE_CAT_ID)
  }

  const [data, setData] = useState(formattedItems)
  const filterData = (activeFilter) => {
    const results = formattedItems.filter((item) => { return item.catId === activeFilter })

    setData(activeFilter === 'all' ? formattedItems : results)
  }

  return (
    <Wrapper>
      <Container>
        <Filters
          label='Our latest news'
          filtersItems={ formattedFilters }
          onChangeCallback={ filterData }
        />
        <MediaSlider items={ data } />
      </Container>
      {
        hasRecipes && (
          <Container id='recipes'>
            <Filters
              label='Our Recipes'
              filtersItems={ [] }
            />
            <MediaSlider items={ recipesItems } />
          </Container>
        )
      }
    </Wrapper>
  )
}

JournalMedias.propTypes = {

}

export default JournalMedias
