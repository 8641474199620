import * as React from 'react'
import PropTypes from 'prop-types'
import LocalizedGatsbyLink from '@/components/LocalizedGatsbyLink'
import { useTheme } from '@emotion/react'
// import { roundPrice } from '@/utils/price'

import resolveAssetURL from '@/utils/resolveAssetURL'

import Icon from '@/components/Icon'

import {
  Container,
  LinkContainer,
  ImageWrapper,
  IconWrapper,
  Image,
  ContentWrapper,
  Category,
  Title,
  Text
} from './style'

const MediaCard = ({ item }) => {
  const theme = useTheme()
  const LinkContainer = Container.withComponent(LocalizedGatsbyLink)

  const renderContent = () => {
    return (
      <>
        <ImageWrapper>
          {/* <IconWrapper>
            <Icon
              name='share'
              size={ 20 }
              color={ theme.colors.white }
            />
          </IconWrapper> */}
          <Image loading='lazy' src={ resolveAssetURL(item.image) } alt={ item?.imageAlt } />
        </ImageWrapper>
        <ContentWrapper>
          <Category>{ item.category }</Category>
          <Title>{ item.title }</Title>
          <Text>{ item.text }</Text>
        </ContentWrapper>
      </>
    )
  }

  if (item.url) {
    return (
      <LinkContainer to={ item.url } title={item.title}>
        { renderContent() }
      </LinkContainer>
    )
  }

  return (
    <Container>
      { renderContent() }
    </Container>
  )
}

MediaCard.propTypes = {
  item: PropTypes.shape({
    category: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
    url: PropTypes.string
  })
}

export default MediaCard
